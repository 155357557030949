import {registerMicroApps, runAfterFirstMounted, setDefaultMountApp, start, initGlobalState} from "qiankun";
import store from "../store";
import {VueJsonp} from 'vue-jsonp'
/**
 * @name 导入想传递给子应用的方法，其他类型的数据皆可按此方式传递
 * @description emit建议主要为提供子应用调用主应用方法的途径
 */


/**
 * @name 导入qiankun应用间通信机制appStore
 */
import appStore from '../utils/app-store'
import {setState} from "../utils/app-store";
/**
 * @name 导入全局常量给子应用
 */
import GLOBAL from '../global'

/**
 * @name 声明子应用挂载dom，如果不需要做keep-alive，则只需要一个dom即可；
 */
const appContainer = "#app-viewport";

/**
 * @name 声明要传递给子应用的信息
 * @param data 主应要传递给子应用的数据类信息
 * @param emits 主应要传递给子应用的方法类信息
 * @param GLOBAL 主应要传递给子应用的全局常量
 * @param utils 主应要传递给子应用的工具类信息（只是一种方案）
 * @param components 主应要传递给子应用的组件类信息（只是一种方案）
 */
let props = {
    data: store.getters,
    GLOBAL,
    Jsonp: VueJsonp
}

/**
 * @name 启用qiankun微前端应用
 * @param {Array} list 应用注册表信息
 */
const qianKunStart = (list) => {
    /**
     * @name 处理子应用注册表数据
     */
    let apps = []; // 子应用数组盒子
    let defaultApp = "/"; // 默认注册应用路由前缀
    list.forEach(i => {
        // if (i.entry.indexOf("cloud-basic") >= 0) {
        //     i.entry = "http://localhost:8082" + i.entry
        // }
        // if (i.entry.indexOf("cloud-drive") >= 0) {
        //     i.entry = "http://localhost:8083" + i.entry
        // }
        // if (i.entry.indexOf("cloud-maintenance") >= 0) {
        //     i.entry = "http://localhost:8084" + i.entry
        // }
        // if (i.entry.indexOf("linkvue") >= 0) {
        //     i.entry = "http://www.hiwooiot.icu/linkvue" + i.entry.substring(24)
        // }
        if (i.menus) {
            apps.push({
                name: i.module,
                entry: i.entry,
                container: appContainer,
                activeRule: i.activeRule,
                props: {...props, routes: i.menus, routerBase: i.activeRule}
            })
        }
        if (i.defaultRegister) defaultApp = i.activeRule;
    });

    /**
     * @name 注册子应用
     * @param {Array} list subApps
     */
    registerMicroApps(
        apps,
        {
            beforeLoad: [
                app => {
                    // console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
                },
            ],
            beforeMount: [
                app => {
                    if(sessionStorage.getItem('menu') && sessionStorage.getItem('userInfo')){
                        setState({
                            msg: {menu: JSON.parse(sessionStorage.getItem('menu')),
                                userInfo: JSON.parse(sessionStorage.getItem('userInfo'))},
                        })
                    }

                    // console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
                },
            ],
            afterUnmount: [
                app => {
                    // console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
                },
            ],
        },
    )

    /**
     * @name 设置默认进入的子应用
     * @param {String} 需要进入的子应用路由前缀
     */
    setDefaultMountApp(defaultApp);

    /**
     * @name 启动微前端
     */
    start({
        // 可选，是否开启预加载，默认为 true。
        prefetch: true,
        // 可选，是否开启沙箱，strictStyleIslation默认为 true。// 从而确保微应用的样式不会对全局造成影响。
        // experimentalStyleIsolation 被设置为 true 时，qiankun 将会通过动态改写一个特殊的选择器约束来限制css的生效范围
        // sandbox: {
        //     strictStyleIsolation: true,
        //     experimentalStyleIsolation: true
        // },
        // 可选，是否为单实例场景，单实例指的是同一时间只会渲染一个微应用。默认为 true。
        singular: true,
        excludeAssetFilter: url => (url.indexOf('map.qq.com') || url.indexOf('open.ys7.com')) || url.indexOf("aliyun.com") !== -1,
        fetch: window.fetch, excludeAssetFilter: (assetUrl) => {
            const whiteList = [];
            const whiteWords = ['qq', 'map', 'ys7', "aliyun"]
            if (whiteList.includes(assetUrl)) {
                return true
            }
            return whiteWords.some(w => {
                return assetUrl.includes(w)
            })
        }
    });

    /**
     * @name 微前端启动进入第一个子应用后回调函数
     */
    runAfterFirstMounted(() => {

    });

    /**
     * @name 启动qiankun应用间通信机制
     */
    appStore(initGlobalState);

}

export default qianKunStart;
